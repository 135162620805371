import {bindable, customElement, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {DialogService} from "aurelia-dialog";

@customElement('sio-uploaded-file-input')
@inject(Client, DialogService)
export class UploadedFile {

    defaults = {
        sizeLimit: 10000000000,
        multiple: false,
        allowedExtensions: null,
    };

    @bindable config;
    @bindable({ defaultBindingMode: 2 }) value;
    @bindable({ defaultBindingMode: 2 }) filename;
    @bindable uploaded;

    constructor(client, dialogService) {
        this.client = client;
        this.dialogService = dialogService;
        this.filename = {};
    }

    bind() {
        this._config = { ...this.defaults, ...this.config };

        // Ensure multiple mode is enabled if configured
        if (this._config.multiple) {
            this._initializeMultipleMode();
        }
    }

    /**
     * Handles file upload completion.
     * @param {Event} e
     */
    async onUploadComplete(e) {
        console.debug('UPLOAD COMPLETE', e);

        const { realPath, fileName } = e.detail || {};

        if (realPath) {
            if (this._config.multiple) {
                this._addFileToMultiple(realPath, fileName);
            } else {
                this._setSingleFile(realPath, fileName);
            }
        } else {
            this._handleInvalidUpload();
        }
    }

    /**
     * Removes a file by index or clears single file value.
     * @param {number|null} index
     */
    removeFile(index = null) {
        if (this._config.multiple) {
            this._removeFileFromMultiple(index);
        } else {
            this._clearSingleFile();
        }
    }

    /**
     * Initializes state for multiple file mode.
     */
    _initializeMultipleMode() {
        if (!Array.isArray(this.value)) {
            this.value = [];
        }
        if (typeof this.filename !== 'object' || Array.isArray(this.filename)) {
            this.filename = {};
        }
    }

    /**
     * Adds a file to multiple file mode.
     * @param {string} realPath
     * @param {string} fileName
     */
    _addFileToMultiple(realPath, fileName) {
        this.filename[realPath] = fileName;
        this.value.push({ file: realPath });
    }

    /**
     * Sets a single file (non-multiple mode).
     * @param {string} realPath
     * @param {string} fileName
     */
    _setSingleFile(realPath, fileName) {
        this.value = realPath;
        this.filename = fileName;
    }

    /**
     * Handles invalid file uploads.
     */
    _handleInvalidUpload() {
        console.error('Invalid file upload');
        if (this._config.multiple) {
            console.error('Invalid file upload in multiple mode');
        } else {
            this.value = null;
            this.filename = null;
        }
    }

    /**
     * Removes a file by index in multiple file mode.
     * @param {number} index
     */
    _removeFileFromMultiple(index) {
        if (index !== null && index >= 0 && index < this.value.length) {
            const removedFile = this.value.splice(index, 1)[0];
            delete this.filename[removedFile.file];
        }
    }

    /**
     * Clears the file in single file mode.
     */
    _clearSingleFile() {
        this.value = null;
        this.filename = null;
    }

    /**
     * Retrieves the file name corresponding to the given file key.
     * @param {string} fileKey
     * @returns {string}
     */
    _getFileName(fileKey) {
        return this.filename && this.filename[fileKey] ? this.filename[fileKey] : 'Unknown file';
    }
}
