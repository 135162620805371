import {bindable, customElement, inject, LogManager} from "aurelia-framework";
import * as _ from "lodash";
import {Form} from "./form";
import {ConfigurationLoader} from "./loader/configuration-loader";
import {DataLoader} from "./loader/data-loader";
import {SubmitHandler} from "./submit/submit-handler";
import {FlashService} from "../flash/flash-service";
import {FormServiceFactory} from "./service/form-service-factory";
import {FormService} from "./service/form-service";
import {EventAggregator} from "aurelia-event-aggregator";

const logger = LogManager.getLogger('form');

@customElement('sio-bulk-form')
@inject(
    ConfigurationLoader,
    DataLoader,
    SubmitHandler,
    FlashService,
    FormServiceFactory,
    EventAggregator
)
export class BulkForm extends Form {

    @bindable data = {};
    @bindable params = {};
    @bindable config;
    @bindable contextObjectRef;

    @bindable submit;

    _entitiesToSubmitCount = 0;
    bulkFormConfig;

    constructor(
        configLoader,
        dataLoader,
        submitHandler,
        flash,
        formServiceFactory,
        ea
    ) {
        super(
            configLoader,
            dataLoader,
            submitHandler,
            flash,
            formServiceFactory,
            ea
        );
    }

    bind()
    {
        logger.debug('Bind', this);

        let data = this.data;

        if ('string' === typeof data) {
            data = {id: data};
        } else if (!data) {
            data = {};
        }

        if (this.contextObjectRef) {
            data.contextObj = this.contextObjectRef;
        }

        this.configLoader.get(this.config, data).then(config => {

            if (!config.labels) {
                config.labels = FormService._defaultLabels;
            }

            if (!config.submitBtnClass) {
                config.submitBtnClass = FormService._defaultRootConfig.submitBtnClass;
            }

            if (!config.actionContainerClass) {
                config.actionContainerClass = FormService._defaultRootConfig.actionContainerClass;
            }

            config.bulkForm.targetCollection.property = 'items';

            this.bulkFormConfig = config;

            if (this.params) {
                this.bulkFormConfig.controlUID = this.params && this.params.controlUID;
            }

            logger.debug('Config loaded', config);

            this.headerFormService = this.formServiceFactory.getFormService( {
                    ...config.bulkForm.header,
                    type: 'form',
                }, {}, this.contextObjectRef
            );
            this.headerFormService.changeCallback = this.headerFormValueChanged.bind(this);

            this.bodyFormService = this.formServiceFactory.getFormService(
                {
                    fields: [
                        config.bulkForm.targetCollection
                    ],
                    type: 'form'
                }, {}, this.contextObjectRef
            );
            this.bodyFormService.changeCallback = this.bodyFormValueChanged.bind(this);

            if (config.bulkForm.targetCollection.subType === 'file') {
                // Find file field

                this.propertyWithTitle = config.bulkForm.targetCollection.propertyWithTitle;
                this.propertyWithFile = config.bulkForm.targetCollection.propertyWithFile;

                this.bulkUploadField = _.find(
                    config.bulkForm.targetCollection.entry.fields,
                    field => field.property === this.propertyWithFile
                );
                this.bulkUploadField = Object.assign({}, this.bulkUploadField, {
                    multiple: true,
                    dontKeepFiles: true
                });

                logger.debug('bulkUploadField', this.bulkUploadField)
            }

        }, error => {

            logger.error('Error loading configuration', error);
        });
    }

    headerFormValueChanged(field)
    {
        let form = _.extend({}, { formService: this.headerFormService }, this);

        this.ea.publish('sio_form_value_changed', {form: form, field: field});
    }

    bodyFormValueChanged(field)
    {
        let form = _.extend({}, { formService: this.bodyFormService }, this);

        this.ea.publish('sio_form_value_changed', {form: form, field: field});
    }

    async internalSubmit()
    {
        let preparedEntities = this._getArrayOfCurrentValues();

        logger.debug('Bulk form component: Submit data', preparedEntities);

        await this._submitAllEntities(preparedEntities);
    }

    _submitAllEntities(preparedEntities)
    {
        this.submitting = true;

        this.ea.publish('sio_form_pre_submit', {form: this});

        logger.debug('Submit with objects', preparedEntities);

        if (!this.submit) {
            this.submit = this.submitHandler.bulkApiSubmit.bind(this.submitHandler);
        }

        return this.submit({
            entities: preparedEntities,
            config: this.bulkFormConfig,
            context: this.contextObjectRef
        }).then(response => {
            this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this});

            this.submitting = false;

            if (this.bulkFormConfig.labels.success) {
                this.flash.success(this.bulkFormConfig.labels.success);
            }

            this.formContainer.dispatchEvent(new CustomEvent('sio-post-submit', {bubbles: true}));

            this.ea.publish('sio_form_post_submit', {config: this.bulkFormConfig, response: response});

            return response;
        }, error => {

            this.submitting = false;

            logger.debug('Error', error);

            if (error.status != 400) {
                this.flash.error(this.bulkFormConfig.labels.communicationError);
            } else {
                this.flash.error(this.bulkFormConfig.labels.validationError);
            }

            let data = error.data;

            if (data) {
                logger.debug("Form validation errors: ", data);

                this.headerFormService.setErrors(data[0]['errors']);

                data.forEach((item, index) => {
                    this.bodyFormService.getFieldByProperty('items').fields[index].setErrors(item);
                });
            }

            return error;
        });
    }

    _getArrayOfCurrentValues()
    {
        let sharedProperties = {};

        // Set only shared properties

        _.each(this.headerFormService.getValue(), (value, key) => {
            sharedProperties[key] = value;
        });

        // Make array of entities that combine both entity's specific and shared properties.

        let preparedEntities = [];

        _.each(this.bodyFormService.getValue().items, (notSharedProperties) => {

            let shared = Object.assign({}, sharedProperties);

            for (const [key, value] of Object.entries(notSharedProperties)) {

                //Special handling for tags atm
                if (key.toLowerCase().includes('tags')) {

                    if (shared[key] == null) {
                        shared[key] = [];
                    }

                    if (value?.length > 0) {
                        shared[key] = shared[key].concat(value);
                    }
                } else {
                    shared[key] = value;
                }

            }

            preparedEntities.push(shared);
        });

        return preparedEntities;
    }

    // Behavior that is specific for (subtype === 'file') goes below
    // -------------------------------------------------------------

    uploadedFile(file)
    {
        logger.debug('Acknowledged about uploaded file', file);

        if (!file) return;

        this.bodyFormService.config.fields[0].add({
            [this.propertyWithTitle]: file.filename,
            [this.propertyWithFile]: file
        });
    }
}
