import {bindable, customElement,inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

@customElement('sio-form-row')
@inject( EventAggregator)

export class FormRow
{
    @bindable field;
    constructor(ea) {
        this.ea = ea;
        this.allowedFields = ['accommodation', 'accommodationAdditionalText'];
    }

    get showPasteButton() {
        return this.allowedFields.includes(this.field?.property);
    }
    
    handleCopyFieldData() {
        this.ea.publish('sio_fieldValueListner', this.field);
     }
}
