import { bindable, customElement, inject } from 'aurelia-framework';
import { Client } from '../../../api/client';
import googleMapsAPI from 'google-maps-api';
import './humboldt-event-planner.less';
import * as _ from 'lodash';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';

@customElement('humboldt-event-planner')
@inject(Client, EventAggregator, BindingSignaler)
export class HumboldtEventPlanner {
    @bindable object;

    plannerForm;
    selectedPostalCodes = []; // Store multiple selected postal codes

    ONLINE_TRAVEL_EVENING = 'online-travel-evening';

    shouldHideMarkers = false;
    includeSelectedPostalCodes = false;
    constructor(client, ea, signaler) {
        this.client = client;
        this.ea = ea;
        this.signaler = signaler;
        this.maps = null;
        this.map = null;
        this.featureLayer = null;
        this.placeService = null;
        this.geocoderService = null;
        this.lastInteractedFeatureIds = [];
        this.lastClickedFeatureIds = [];
        this.filteredFeatureIds = [];
        this.apiKey = null;
        this.mapId = null;
        this.eventLocationMarkers = [];
        this.infoWindow = null;
    }

    async bind() {
        try {

            await Promise.all([this.initializeMap(), this.formValueChangedEvent()]);
            await this.initializeLocalVars();
        } catch (error) {
            console.error("Error initializing map:", error);
        }
    }
    async initializeLocalVars() {
        this.includeSelectedPostalCodes =  this.object.includeSelectedPostalCodes;
        this.selectedPostalCodes =  this.object.selectedPostalCodes;
        this.filterPostalCodes = this.object.filterPostalCodes;
        this.shouldHideMarkers = this.ONLINE_TRAVEL_EVENING === this.object.eventCategory;
        await this.highlightFilteredPostalCodes();
        await this.changeSelectedPostalCodes();
        await this.loadEventLocations();
    }
    async initializeMap() {
        const startCoords = { lat: 50.9375, lng: 6.9603 }; // Köln Coords

        await this.loadApiSettings();
        if (!this.apiKey || !this.mapId) {
            console.error("Google Maps API key or Map ID is missing!");
            return;
        }

        this.maps = await this.loadGoogleMapsAPI();
        if (!this.maps || typeof this.maps.Map !== "function") {
            console.error("Google Maps API failed to load properly.");
            return;
        }

        const mapElement = document.getElementById('planning-map');
        const inputElement = document.getElementById('city-search');

        if (!mapElement || !inputElement) {
            console.error('Map or input element not found!');
            return;
        }

        this.map = this.createMap(mapElement, startCoords);
        this.placeService = new this.maps.places.PlacesService(document.createElement("div"));

        this.geocoderService = new this.maps.Geocoder();

        this.infoWindow = new this.maps.InfoWindow({
        });

        this.setupAutocomplete(inputElement);
        await this.initFeatureLayer();
    }

    async loadApiSettings() {
        if (this.apiKey && this.mapId) return;
        const settings = await this.client.get("maps/google/apikey", true);
        this.apiKey = settings?.apiKey ?? "";
        this.language = settings?.language ?? "de";
        this.mapId = settings?.mapId ?? "1bf5295b744a394a";
    }

    async loadGoogleMapsAPI() {
        const loadMaps = googleMapsAPI(
            {
                key: this.apiKey,
                language: this.language,
            }, ['places']);
        return await loadMaps();
    }

    createMap(mapElement, startCoords) {
        return new this.maps.Map(mapElement, {
            center: startCoords,
            zoom: 12,
            mapId: this.mapId,
            mapTypeControl: false,
            streetViewControl: false,
        });
    }

    setupAutocomplete(inputElement) {
        if (!this.maps.places || typeof this.maps.places.Autocomplete !== "function") {
            console.error("Google Places API is not available!");
            return;
        }

        const autocomplete = new this.maps.places.Autocomplete(inputElement, {
            types: ["geocode"],
            componentRestrictions: { country: "DE" }
        });

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            this.zoomToPlace(place);
        });
    }

    async initFeatureLayer() {
        if (!this.mapId) {
            console.error("Feature Layer cannot be initialized without a valid Map ID.");
            return;
        }

        this.featureLayer = this.map.getFeatureLayer("POSTAL_CODE");
        if (!this.featureLayer) {
            console.error("Feature Layer could not be initialized.");
            return;
        }

        this.featureLayer.addListener("click", this.layerHandleClick.bind(this));
        this.featureLayer.addListener("mousemove", this.layerHandleMouseMove.bind(this));

        this.map.addListener("mousemove", () => {
            if (this.lastInteractedFeatureIds?.length) {
                this.lastInteractedFeatureIds = [];
                this.featureLayer.style = this.applyStyle.bind(this);
            }
        });

        this.featureLayer.style = this.applyStyle.bind(this);
    }

    async layerHandleClick(event) {

        const clickedData = await Promise.all(event.features.map(async (feature) => {
            const place = await this.placeDetails(feature.placeId);
            if (!place || !place.address_components) {
                console.error("No address components found for this place.");
                return null;
            }

            this.zoomToPlace(place);

            const postalCode = place.address_components.find(component =>
                component.types.includes("postal_code")
            )?.long_name;
            if (!postalCode) return null;

            return { postalCode, placeId: feature.placeId };
        }));
        // Filter out invalid results
        const validData = clickedData.filter(Boolean);

        await validData.forEach(({ postalCode, placeId }) => {
            const index = this.selectedPostalCodes.indexOf(postalCode);

            if (index !== -1) {
                // If already selected, remove from both lists
                this.selectedPostalCodes.splice(index, 1);
                this.lastClickedFeatureIds.splice(index, 1);
            } else {
                // If not selected, add to both lists
                this.selectedPostalCodes.push(postalCode);
                this.lastClickedFeatureIds.push(placeId);
            }
        });

        // Apply style updates and reload event locations
        this.changeSelectedPostalCodes();

        await this.loadEventLocations();
    }
    layerHandleMouseMove(event) {
        this.lastInteractedFeatureIds = event.features.map((f) => f.placeId);
        this.featureLayer.style = this.applyStyle.bind(this);
    }


    async loadEventLocations() {
        this.clearMarkers(); // Clear existing markers
        try {
            const eventLocations = await this.client
                .get(`event-planner/event-locations/search?selectedPostalCodes=${JSON.stringify(this.selectedPostalCodes)}`, 60);

            if (eventLocations?.length) {
                eventLocations.forEach(location => this.addMarkerToMap(location));
            }
        } catch (error) {
            console.error(`Error fetching locations`, error);
        }

    }

    clearMarkers() {
        this.eventLocationMarkers.forEach(marker => marker.setMap(null));
        this.eventLocationMarkers = [];
    }

    addMarkerToMap(location) {
        if (this.shouldHideMarkers) {
            return;
        }

        if (!location?.address?.coordinates) {
            console.error("Invalid location data.");
            return;
        }
        const { lat, lng } = location.address.coordinates;

        if (!this.map) {
            console.error("Map instance is not initialized.");
            return;
        }

        const markerIcon = {
            url: "https://humboldt-tzcd-file.s.io/dcc21af3-abbf-49a6-a4bd-6cdc6aa83b12/home-pin.png"
        };

        const marker = new this.maps.Marker({
            position: { lat, lng },
            map: this.map,
            icon: markerIcon
        });

        const infoContent = this.createMarkerTemplate(location);

        marker.addListener("click", () => {

            this.infoWindow.setContent(infoContent);
            this.infoWindow.open(this.map, marker);

            this.plannerForm.formService
                ?.getFieldByProperty('eventLocation')
                ?.setValue(location);

            this.currentLocation = location;
            this.signaler.signal('sio_form_value_changed');
        });

        this.eventLocationMarkers.push(marker);
    }

    createMarkerTemplate(location) {

        const title = location.title?.de || location.title;
        const address = `${location.address.addressLine1}, ${location.address.city}, ${location.address.zip}, ${location.address.country}`;
        const phone = location?.phones[0]?.phone || "N/A"; // Get the first phone number or set "N/A"
        const url = `/view/tourism-event/location?bulk=false&id=${location.id}&modelId=tourism-event%2Flocation`;

        const imageHeader =  location?.images[0]?.file?.previewUrl?.replace('$width', '300').replace('$height', '300');
        const image = imageHeader ? `<img src="${imageHeader}" alt="Parking Image" />` : '';
        return `
            <div class='custom-info'>
                 ${image}
                <div class='info-content'>
                    <a href='${url}' target='_blank'>${title}</a>
                    <br/>
                    <span><strong>Address:</strong>${address}</span>
                    <br/>
                    <span><strong>Phone:</strong> ${phone}</span>
                </div>
        </div>
    `;
    }

    placeDetails(placeId) {
        const OK = this.maps.places.PlacesServiceStatus.OK;
        return new Promise((resolve, reject) => {
            this.placeService.getDetails({ placeId }, (results, status) => {
                if (status !== OK) {
                    reject(status);
                }
                resolve(results);
            });
        });
    }

    zoomToPlace(place) {
        if (!place.geometry) {
            console.error("No details available for input: '" + place.name + "'");
            return;
        }
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(12);
    }

    applyStyle(params) {
        const placeId = params.feature.placeId;

        if (this.lastClickedFeatureIds.includes(placeId)) {
            return {
                strokeColor: "#cb0f0f",
                strokeOpacity: 1.0,
                strokeWeight: 1.5,
                fillColor: "#cb0f0f",
                fillOpacity: 0.5
            };
        }

        if (this.filteredFeatureIds.includes(placeId)) {
            return {
                strokeColor: "#cb0f0f",
                strokeOpacity: 1.0,
                strokeWeight: 1.5,
                fillColor: "#cb0f0f",
                fillOpacity: 0.5
            };
        }

        if (this.lastInteractedFeatureIds.includes(placeId)) {
            return {
                strokeColor: "#cb0f0f",
                strokeOpacity: 1.0,
                strokeWeight: 3.0,
                fillColor: "white",
                fillOpacity: 0.1
            };
        }

        return {
            strokeColor: "#cb0f0f",
            strokeOpacity: 1.0,
            strokeWeight: 1.0,
            fillColor: "white",
            fillOpacity: 0.1
        };
    }

    async formValueChangedEvent() {
        await this.ea.subscribe('sio_form_value_changed', async ({ form, field }) => {
            if (field.property === 'radius') {
                 await this.drawResizableCircle(form);
            }

            if (field.property === 'eventCategory') {
                this.shouldHideMarkers = this.ONLINE_TRAVEL_EVENING === form.formService.getValue()?.eventCategory;
            }

            this.includeSelectedPostalCodes =  form.formService.getValue()?.includeSelectedPostalCodes
            this.filterPostalCodes = form.formService.getFieldByProperty('filterPostalCodes')?.default;
            await this.highlightFilteredPostalCodes();

            if (this.shouldHideMarkers) {
                this.clearMarkers();
            } else {
                await this.loadEventLocations();
            }

            if (field.property === 'eventLocation') {
                const eventLocation = form.formService.getValue()?.eventLocation;
                if (eventLocation) {
                    const data = {
                        embeds: ['title', 'images', 'images.file']
                    };

                    const location = await this.client.get(`${eventLocation.modelId}/${eventLocation.id}`, data);

                    this.addMarkerToMap(location);
                    this.map.setCenter(location?.address?.coordinates);

                    // Store location for future use
                    this.currentLocation = location;
                }
            }
        });
    }

    // Function to get postal codes within the radius
    async drawResizableCircle(form) {
        if (!this.currentLocation?.address?.coordinates) return;
        const radius = form.formService.getValue()?.radius;

        if (!radius) {
            return;
        }

        // Remove existing circle if any
        if (this.mapCircle) {
            this.mapCircle.setMap(null);
        }

        // Draw a new resizable circle
        this.mapCircle = new this.maps.Circle({
            center: this.currentLocation?.address?.coordinates,
            radius: radius * 1000, // Convert km to meters
            map: this.map,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true, // Allow resizing
            draggable: false // Keep the center fixed
        });

        await this.updatePostalCodes(radius);

        // Listen for changes in the circle radius
        this.maps.event.addListener(this.mapCircle, "radius_changed", async () => {
            const newRadius = this.mapCircle.getRadius() / 1000; // Convert meters to km

            this.plannerForm.formService.getFieldByProperty('radius').setValue(newRadius);

            // Update postal codes when radius is changed
            await this.updatePostalCodes(newRadius);
        });
    }

    // Function to update postal codes based on the new radius
    async updatePostalCodes(radius) {
        if (!this.currentLocation?.address?.coordinates) return;

        this.selectedPostalCodes = []; // Reset selected postal codes
        this.lastClickedFeatureIds = [];

        await this.getPostalCodesInRadius(radius);

        this.changeSelectedPostalCodes();
    }

    // Function to get postal codes within the radius
    async getPostalCodesInRadius(radius) {
        const numPoints = 100;
        const center = this.currentLocation?.address?.coordinates;

        if (!center) {
            console.error("Center location is missing.");
            return;
        }

        const geocodePromises = [];

        for (let i = 0; i < numPoints; i++) {
            // Generate random points inside the circle
            const randomRadius = Math.sqrt(Math.random()) * radius; // Distributes points evenly inside
            const randomAngle = Math.random() * 2 * Math.PI;

            const latOffset = (randomRadius / 111) * Math.cos(randomAngle);
            const lngOffset = (randomRadius / (111 * Math.cos(center.lat * (Math.PI / 180)))) * Math.sin(randomAngle);

            const point = {
                lat: center.lat + latOffset,
                lng: center.lng + lngOffset
            };

            geocodePromises.push(this.reverseGeocode(point).catch(() => null));
        }

        // Ensure the center point is included
        geocodePromises.push(this.reverseGeocode(center).catch(() => null));

        try {
            const results = await Promise.all(geocodePromises);

            // Use a Set to ensure unique postal codes
            const uniqueResults = new Map();
            results.forEach(res => {
                if (res && res.postalCode && !uniqueResults.has(res.postalCode)) {
                    uniqueResults.set(res.postalCode, res.placeId);
                }
            });

            // Store results
            this.selectedPostalCodes = [...uniqueResults.keys()];
            this.lastClickedFeatureIds = [...uniqueResults.values()];
        } catch (error) {
            console.error("Error fetching postal codes:", error);
        }
    }
    // Reverse geocode a location to get the postal code
    async reverseGeocode(location) {
        return new Promise((resolve, reject) => {
            this.geocoderService.geocode({ location}, (results, status) => {
                if (status === "OK" && results.length > 0) {
                    for (const result of results) {
                        if (!result.types.includes("postal_code")) {
                            continue;
                        }
                        for (const component of result.address_components) {
                            if (component.types.includes("postal_code")) {
                                resolve({postalCode: component.long_name, placeId: result.place_id});
                                return;
                            }
                        }
                    }
                }
                reject(null);
            });
        });
    }

    async highlightFilteredPostalCodes() {
        try {
            this.filteredFeatureIds = [];
            let allPostCode = this.filterPostalCodes || [];


            if (this.includeSelectedPostalCodes && this.selectedPostalCodes) {
                allPostCode = [...new Set([...allPostCode, ...this.selectedPostalCodes])];
            }

            // Fetch all place IDs in parallel
            const placeIds = await Promise.all(
                allPostCode?.map(async (postalCode) => {
                    try {
                        return await this.getPlaceIdByPostalCode(postalCode);
                    } catch (error) {
                        return null;
                    }
                })
            );

            // Filter out null values and update lastClickedFeatureIds
            this.filteredFeatureIds = placeIds.filter(id => id !== null);
        } catch (error) {
            console.error("Error fetching place IDs:", error);
        }
    }


    async getPlaceIdByPostalCode(postalCode) {
        try {
            return await new Promise((resolve, reject) => {
                this.geocoderService.geocode({ address: postalCode, componentRestrictions: { country: 'DE' } }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        resolve(results[0].place_id);
                    } else {
                        reject(null);
                    }
                });
            });
        } catch (error) {
           return null;
        }
    }

    changeSelectedPostalCodes() {

        let selectedPostalCodesField =  this.plannerForm
            ?.formService
            ?.getFieldByProperty('selectedPostalCodes');
        selectedPostalCodesField?.setValue(this.selectedPostalCodes);

        this.plannerForm?.formValueChanged(selectedPostalCodesField);
        this.featureLayer.style = this.applyStyle.bind(this);
    }

    clearForm() {
        if (this.plannerForm && this.plannerForm.formService) {
            _.forEach(this.plannerForm.formService.config.fields, (field) => {
                field.resetValue();
            });
        }

        let exclusivePlanningField = this.plannerForm
            ?.formService
            ?.getFieldByProperty('exclusivePlanning');
        exclusivePlanningField?.setValue('yes');

        this.plannerForm?.formValueChanged(exclusivePlanningField);

        this.clearMarkers(); // Remove markers from the map
        this.selectedPostalCodes = []; // Reset selected postal codes
        this.lastClickedFeatureIds = [];
        this.filteredFeatureIds = [];
        this.mapCircle?.setMap(null);
        this.featureLayer.style = this.applyStyle.bind(this);
    }
}
